import React from 'react';

import {
  motion,
} from 'framer-motion';

import LightsData from './LightsData.json';

export default function Decoration(props) {
  return (
    <>
      <path d="M1571.55,1206.05L1578.67,1245.31C1367,1320.41 1085.66,1216.79 996.411,1060.96L1011.79,1020.62C1133.16,1184.06 1381.74,1275.56 1571.55,1206.05Z" style={{ fill: 'rgb(255,0,0)' }} />
      <g transform="matrix(1,0,0,1,-23.1178,236.512)">
        <path d="M1616.66,1091.02L1624.19,1133.78C1506.02,1182.96 1034.55,1250.55 912.121,1229.69C913.022,1215.78 914.41,1201.76 916.913,1187.53C1095.32,1196.35 1486.77,1149.71 1616.66,1091.02Z" style={{ fill: 'rgb(255,0,0)' }} />
      </g>
      <g transform="matrix(1,0,0,1,-11.7368,365.971)">
        <path d="M1571.46,1294.67C1563.56,1306.39 1555,1317.67 1546.12,1328.73C1528.25,1348.27 1170.78,1189.48 917.658,1222.43C914.547,1209.6 908.073,1192.42 906.47,1179.16C1300.25,1178.51 1537.92,1311.07 1571.46,1294.67Z" style={{ fill: 'rgb(255,0,0)' }} />
      </g>
      {
        LightsData.lights.map((light, index) => (
          <g transform={light.location.transform}>
            {props.data.charge_point.charging.display
              ? (
                <motion.ellipse
                  // eslint-disable-next-line react/no-array-index-key
                  key={`light-${index}`}
                  cx={light.location.cx}
                  cy={light.location.cy}
                  rx={light.location.rx}
                  ry={light.location.ry}
                  fill={index % 2 === 0 ? '#FFFF00' : '#0000FF'}
                  initial={{
                    fill: index % 2 === 0 ? '#FFFF00' : '#0000FF',
                  }}
                  animate={{
                    fill: index % 2 === 0 ? '#0000FF' : '#FFFF00',
                  }}
                  transition={{
                    duration: 1,
                    repeat: 'Infinity',
                    repeatType: 'reverse',
                  }}
                />
              )
              : (
                <ellipse
                  // eslint-disable-next-line react/no-array-index-key
                  key={`light-${index}`}
                  cx={light.location.cx}
                  cy={light.location.cy}
                  rx={light.location.rx}
                  ry={light.location.ry}
                  fill="#C8C8C8"
                />
              )}
          </g>
        ))
      }
    </>
  );
}
