import * as React from 'react';

import List from './List';

export default function Layout(props) {
  return (
    <List
      data={props.data}
      loading={props.loading}
      lastElement={props.lastElement}
      mode={props.mode}
    />
  );
}
